<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Personal Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1" @submit="method">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Birth Date" label-for="birth-date">
            <flat-pickr
              v-model="userData.birthDate"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                locale: Turkish,
              }"
              placeholder="DD-MM-YYYY"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Mobile Number" label-for="mobile">
            <b-form-input id="mobile" v-model="userData.mobileNumber" />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Web site" label-for="website">
            <b-form-input id="website" v-model="userData.website" />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Language" label-for="language">
            <v-select
              v-model="userData.languages"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Gender" label-for="gender" label-class="mb-1">
            <b-form-radio-group
              id="gender"
              v-model="userData.genderId"
              :options="genderOptions"
              value="0"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Contact Options -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group
            label="Contact Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="userData.candidateContactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Candidate Profile" label-for="user-role">
            <v-select
              v-model="userData.candidateProfileTypeId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="true"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-button
            variant="outline-primary"
            class="mt-2"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            />

            Updade CV
          </b-button>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">Address</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Address Line 1 -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Address Line 1" label-for="address-line-1">
            <b-form-input id="address-line-1" v-model="userData.addressLine1" />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Address Line 2" label-for="address-line-2">
            <b-form-input
              id="address-line-2"
              v-model="userData.addressLine2"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Postcode -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Postcode" label-for="postcode">
            <b-form-input
              id="postcode"
              v-model="userData.postCode"
              type="number"
              placeholder="597626"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="City" label-for="city">
            <b-form-input id="city" v-model="userData.city" />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="State" label-for="state">
            <b-form-input
              id="state"
              v-model="userData.state"
              placeholder="Manhattan"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Country" label-for="country">
            <b-form-input
              id="country"
              v-model="userData.country"
              placeholder="United States"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="success"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Save
          </b-button>
          <!-- <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BMedia,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadio,
  BIcon,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from "vue-property-decorator";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BMedia,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BIcon,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },

    method: { type: Function },
  },
  mounted() {
    this.method();
  },
  setup(props) {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatarFileUrl = base64;

        // console.log("base64",refInputEl.value.files[0]);

        store
          .dispatch("candidate/updateCv", {
            id: props.userData.id,
            base64: refInputEl.value.files[0],
          })
          .then((response) => {
             store
          .dispatch("candidate/fetchUser", {
            id: props.userData.id,
          })
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "CV updated",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
       
         
       
      }
    );

    const languageOptions = [
      "English",
      "Spanish",
      "French",
      "Russian",
      "German",
      "Arabic",
      "Sanskrit",
      "Turkish",
    ];
    const roleOptions = [
      { label: "Green", value: 1 },
      { label: "Red", value: 2 },
      { label: "Blue", value: 3 },
      { label: "Yellow", value: 4 },
    ];
    const genderOptions = [
      { text: "Male", value: 1 },
      { text: "Female", value: 2 },
    ];

    const contactOptionsOptions = [
      {
        text: "Email",
        value: { contactOptionId: 0, contactOptionName: "Email" },
      },
      {
        text: "Sms",
        value: { contactOptionId: 1, contactOptionName: "Sms" },
      },
      {
        text: "Call",
        value: { contactOptionId: 2, contactOptionName: "Call" },
      },
    ];

    return {
      roleOptions,
      languageOptions,
      // userDataInfo,
      genderOptions,
      contactOptionsOptions,
      inputImageRenderer,
      refInputEl,
      previewEl,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
