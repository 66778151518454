<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatarFileUrl"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>

      <div class="d-flex flex-wrap">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon ml-0"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />

          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-secondary"
          class="btn-icon ml-0"
          @click="deletePic"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form @submit="method">
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Full Name" label-for="username">
            <b-form-input id="username" v-model="userData.fullName" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Title" label-for="Title">
            <b-form-input id="Title" v-model="userData.title" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="University" label-for="University">
            <b-form-input id="University" v-model="userData.university" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
            <b-form-group label="University Department" label-for="UniversityDepartment">
              <b-form-input id="UniversityDepartment" v-model="userData.universityDepartment" />
            </b-form-group>
          </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="City" label-for="City">
            <b-form-input id="City" v-model="userData.currentCity" />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->

        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Company" label-for="Company">
            <b-form-input id="Company" v-model="userData.currentCompany" />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- PERMISSION TABLE -->
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <span class="align-middle ml-50">Skill Levels</span>
          </b-card-title>
        <!--   <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
            <span class="text-nowrap">Add Skills</span>
          </b-button>-->
        </b-card-header>
        <!--  <b-table striped responsive class="mb-0" :items="skillsData">
          <template #cell(module)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-tr>
              <b-form-radio-group
                :id="`radio-group%${data.id}`"
                v-model="data.value"
              >
                <b-td>
                  <b-form-radio />
                </b-td>
              </b-form-radio-group>
            </b-tr>
          </template>
        </b-table> -->

        <v-table class="mb-0 table table-striped" style="display: table">
          <thead slot="head">
            <div>
              <th class="w-module-n">Skills</th>
              <th class="w-module-n">Bad</th>
              <th class="w-module-n">Average</th>
              <th class="w-module-n">Good</th>
              <th class="w-module-n">Perfect</th>
            </div>
          </thead>
          <tbody>
            <tr
              v-for="skill in skillsData"
              :key="skill.value"
              class="w-module-n"
            >
              <b-form-radio-group
                :name="skill.value"
                v-model="permissionsData[skill.value]"
                v-on:change="changeCalView($event, skill.value)"
                :aria-describedby="ariaDescribedby"
              >
                <td class="w-module-n">
                  {{ skill.text }}
                </td>
                <td class="w-module-n">
                  <b-form-radio name="Bad" value="0" />
                </td>
                <td class="w-module-n">
                  <b-form-radio name="Average" value="1" />
                </td>
                <td class="w-module-n">
                  <b-form-radio name="Good" value="2" />
                </td>
                <td class="w-module-n">
                  <b-form-radio name="Perfect" value="3" />
                </td>
              </b-form-radio-group>
            </tr>
          </tbody>
        </v-table>
      </b-card>

      <!-- Action Buttons -->
      <b-button
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
      >
        Save
      </b-button>
      <!-- <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button> -->
    </b-form>
    <b-row class="mt-3">
      <b-col cols="12" md="12">
     <!--    <user-view-user-timeline-card :user-data="userData" />-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BIcon,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import UserViewUserTimelineCard from "../users-view/UserViewUserTimelineCard.vue";
import UserListAddNew from "./UserListAddNew.vue";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from "vue-property-decorator";

export default {
  components: {
    UserListAddNew,
    ToastificationContent,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    UserViewUserTimelineCard,
    BFormRadioGroup,
    BFormRadio,
    BIcon,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    skillsData: {
      type: Object,
      required: true,
    },
    method: { type: Function },
  },
  data() {
    return {
      permissionsData: [],
      file: null,
      candidateSkillLevels: [],
    };
  },
  methods: {
    changeCalView: function (event, id) {
      // for (var i = 0; i < this.candidateSkillLevels.length; i++) {
      //   if (this.candidateSkillLevels[i].skillSetId === id) {
      //     this.candidateSkillLevels.splice(i, 1);
      //   }
      // }
      // this.candidateSkillLevels.push({
      //   skillSetId: id,
      //   skillLevelId: Number(event),
      // });
      // this.userData.candidateSkillLevels = this.candidateSkillLevels;
      this.candidateSkillLevels=[];
      for (const [key, value] of Object.entries(this.permissionsData)) {
        this.candidateSkillLevels.push({
          skillSetId: key,
          skillLevelId: Number(value),
        });
      }
      this.userData.candidateSkillLevels = this.candidateSkillLevels;
    },
    SkillLevels() {
      for (var i = 0; i < this.userData.candidateSkillLevels.length; i++) {
        var obj = {};
        obj[this.userData.candidateSkillLevels[i].skillSetId] =
          this.userData.candidateSkillLevels[i].skillLevelId.toString();

        this.permissionsData = { ...this.permissionsData, ...obj };
      }
    },
    deletePic() {
      store
        .dispatch("candidate/deletePic", {
          id: this.userData.id,
        })
        .then((response) => {
          this.userData.avatarFileUrl = "";
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    },
  },
  mounted() {
    this.method();
  },
  beforeMount() {
    this.SkillLevels();
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];
    const DataSkill = ref([
      { label: "Bad", value: 0 },
      { label: "Average", value: 1 },
      { label: "Good", value: 2 },
      { label: "Perfect", value: 3 },
    ]);

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatarFileUrl = base64;

        // console.log("base64",refInputEl.value.files[0]);

        store
          .dispatch("candidate/addPhoto", {
            id: props.userData.id,
            base64: refInputEl.value.files[0],
          })
          .then((response) => {
           
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "Avatar updated",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(error.response.data.errors)) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text:value,
              },
            });
            }
          });
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      DataSkill,
      isAddNewUserSidebarActive,
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.w-module {
  width: 100vw !important;
}
.w-module-n {
  width: 100% !important;
}
</style>
